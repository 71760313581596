$base_color: #fff;
$base_font_color: #222C44;
$base_font_color_hover: #fff;
$header_font_color: #0E2E5A;
$special_color: #4BBCFF;
$svg_color: #B7C1CC;
$border-color: #D6E8FE;

$active_select_bg_color: #4BBCFF;

.mycalendar2_datepicker {
    width:100%;

    .base_bg_color { background-color:$base_color; }
    .base_font_color { color:$base_font_color; fill:$base_font_color; }
    .base_font_color_hover {
        color:$base_font_color_hover;
        fill:$base_font_color_hover;
        a {
            color:$base_font_color_hover;
        }
    }
    .header_font_color { color: $header_font_color; }
    .special_bg_color { background-color:$special_color; }
    .special_font_color { color:$special_color; }
    .special_hover_font_color { color:$base_color; }


    .calendar_content {
        position:relative;
        padding:15px;

        header.months_change {
            width:100%;
            border-bottom:1px solid $border-color;
            padding-bottom: 18px;
            padding-top: 10px;

            .left {
                padding-top:7px;
                width:30px;
                display:inline-block;
                vertical-align: top;
                text-align: right;

                cursor: pointer;

                &:hover svg {
                    fill:$base_font_color;
                }

                svg {
                    height:20px;
                    fill:$svg_color;
                }
            }

            .title {
                width:calc(100% - 60px);
                display:inline-block;
                vertical-align: top;

                text-align: center;

                .month {
                    font-weight: 300;
                    font-size:15px;
                    line-height:20px;
                    font-weight: bold;

                }

                .year {
                    font-size:13px;
                }
            }

            .right {
                padding-top:7px;
                width:30px;
                display:inline-block;
                vertical-align: top;
                text-align: left;

                cursor: pointer;

                &:hover svg {
                    fill:$base_font_color;
                }

                svg {
                    height:20px;
                    fill:$svg_color;
                }
            }
        }

        .days_table {
            width:100%;
            display:table;

            font-size:14px;

            .tr {
                display:table-row;
                .td {
                    display:table-cell;
                    text-align: center;
                    width:14.2%;
                    height:35px;

                    p {
                        padding-top:8px;
                    }
                }
            }

            .thead {
                display:table-header-group;

                .td {
                    font-weight: bold;
                }
            }

            .tbody {
                display: table-row-group;
            }

            .td.mprev, .td.mnext {
                p {
                    opacity:0.2;
                }
            }

            .td.active, .td.active_selected {
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                border-radius: 20px;

                //position:relative;
                cursor: pointer;

                &.active:hover {

                    &.ajax_box_day {
                        -webkit-border-radius: 20px;
                        -webkit-border-bottom-right-radius: 0px;
                        -webkit-border-bottom-left-radius: 0px;
                        -moz-border-radius: 20px;
                        -moz-border-radius-bottomright: 0px;
                        -moz-border-radius-bottomleft: 0px;
                        border-radius: 20px;
                        border-bottom-right-radius: 0px;
                        border-bottom-left-radius: 0px;
                    }

                    background-color:$active_select_bg_color;

                    z-index: 3;

                    .popup_html {
                        display:block;
                    }
                }

                p {
                    opacity:1;
                }
            }

            .td.active_selected {
                background-color:$active_select_bg_color;
                color:$base_color;
            }

        }
    }

    .popup_html {
        display:none;

        position:absolute;
        background-color:$active_select_bg_color;
        //top:100%;
        //right:0;
        left:0;

        text-align: left;

        width:100%;

        //-webkit-border-radius: 5px;
        //-webkit-border-top-right-radius: 0;
        //-moz-border-radius: 5px;
        //-moz-border-radius-topright: 0;
        //border-radius: 5px;
        //border-top-right-radius: 0;

        ul {
            li {
                padding:5px 10px;
                border-bottom:1px solid $base_color;

                &:last-of-type {
                    border-bottom:0;
                }
            }
        }

    }
}


